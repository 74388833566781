import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import i18n from "i18next";
import Cookies from "js-cookie";
import logo from "../img/logo.svg";
import logo_mob from "../img/logo.svg";
import MobileMenu from "./MobileMenu";

function Header() {
    const { t } = useTranslation();
    const location = useLocation();

    const languages = [
        { value: 'en', label: 'EN' },
        { value: 'es', label: 'ES' },
        { value: 'it', label: 'IT' },
        { value: 'pl', label: 'PL' },
        { value: 'de', label: 'DE' },
        { value: 'fr', label: 'FR' },
        { value: 'cz', label: 'CZ' },
    ]

    const storedLang = Cookies.get("i18next");
    const [currentLang, setCurrentLang] = useState(storedLang || 'en');

    useEffect(() => {
        if (storedLang && storedLang !== currentLang) {
            setCurrentLang(storedLang);
            i18n.changeLanguage(storedLang);
        }
        // eslint-disable-next-line
        document.body.setAttribute('dir', storedLang === 'ar' ? 'rtl' : 'ltr');
    }, [storedLang]);
    

    const handleChangeLang = (selectedLang) => {
        setCurrentLang(selectedLang.value);
        i18n.changeLanguage(selectedLang.value);
        Cookies.set("i18next", selectedLang.value);
    };

    return (
        <div className="header-container">
            <div className="hidden max-w-[1280px] h-[60px] mx-auto xl:flex items-center justify-between">
                <div className="flex items-center gap-[50px]">
                    <Link to="/"><img src={logo} alt="" className="w-[100px]"/></Link>
                    <Link to="/raise"><p className={`text-[16px] roboto ${location.pathname === '/raise' ? 'text-[#00685E] font-[500]' : 'text-[#000000B2]'}`}>{t('header.1')}</p></Link>
                    <Link to="/listing"><p className={`text-[16px] roboto ${location.pathname === '/listing' ? 'text-[#00685E] font-[500]' : 'text-[#000000B2]'}`}>{t('header.2')}</p></Link>
                    <Link to="/trading"><p className={`text-[16px] roboto ${location.pathname === '/trading' ? 'text-[#00685E] font-[500]' : 'text-[#000000B2]'}`}>{t('header.3')}</p></Link>
                    <Link to="/investing"><p className={`text-[16px] roboto ${location.pathname === '/investing' ? 'text-[#00685E] font-[500]' : 'text-[#000000B2]'}`}>{t('header.4')}</p></Link>
                    <Link to="/about"><p className={`text-[16px] roboto ${location.pathname === '/about' ? 'text-[#00685E] font-[500]' : 'text-[#000000B2]'}`}>{t('header.5')}</p></Link>
                </div>
                <div className="flex items-center gap-[12px]">
                    <Dropdown
                        options={languages}
                        placeholder="EN"
                        onChange={handleChangeLang}
                        value={languages.find(lang => lang.value === currentLang)}
                        className="mr-[10px]"
                    />
                    <a href="https://user.enxb.org/">
                        <div className="flex items-center justify-center gap-[4px] cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <g clip-path="url(#clip0_4864_466)">
                                    <path d="M8.99999 0C7.21996 0 5.47991 0.527841 3.99986 1.51677C2.51982 2.50571 1.36627 3.91131 0.68508 5.55585C0.00389196 7.20038 -0.174338 9.00998 0.172929 10.7558C0.520196 12.5016 1.37736 14.1053 2.63603 15.364C3.89471 16.6226 5.49835 17.4798 7.24418 17.8271C8.99001 18.1743 10.7996 17.9961 12.4441 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 8.99999 0ZM13.0909 9.81818H9.81818V13.0909C9.81818 13.3079 9.73197 13.516 9.57854 13.6694C9.4251 13.8229 9.21699 13.9091 8.99999 13.9091C8.783 13.9091 8.57489 13.8229 8.42145 13.6694C8.26801 13.516 8.18181 13.3079 8.18181 13.0909V9.81818H4.90909C4.69209 9.81818 4.48398 9.73198 4.33054 9.57854C4.17711 9.4251 4.0909 9.21699 4.0909 9C4.0909 8.783 4.17711 8.5749 4.33054 8.42146C4.48398 8.26802 4.69209 8.18182 4.90909 8.18182H8.18181V4.90909C8.18181 4.69209 8.26801 4.48399 8.42145 4.33055C8.57489 4.17711 8.783 4.09091 8.99999 4.09091C9.21699 4.09091 9.4251 4.17711 9.57854 4.33055C9.73197 4.48399 9.81818 4.69209 9.81818 4.90909V8.18182H13.0909C13.3079 8.18182 13.516 8.26802 13.6694 8.42146C13.8229 8.5749 13.9091 8.783 13.9091 9C13.9091 9.21699 13.8229 9.4251 13.6694 9.57854C13.516 9.73198 13.3079 9.81818 13.0909 9.81818Z" fill="#00685E"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_4864_466">
                                    <rect width="18" height="18" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <p className="text-[#00685E] roboto text-[16px] font-[500]">{t('header.button')}</p>
                        </div>
                    </a>
                    <div className="h-[28px] w-[2px] rounded-t-full rounded-b-full bg-[#00685E]"></div>
                    <a href="https://user.enxb.org/">
                        <div className="flex items-center justify-center gap-[4px] cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <g clip-path="url(#clip0_4864_464)">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 0C13.9624 0 18 4.03762 18 9C18 13.9624 13.9624 18 9 18C4.03762 18 0 13.9624 0 9C0 4.03762 4.03762 0 9 0ZM14.6165 13.5001C13.2958 15.1451 11.2687 16.2 9 16.2C6.73124 16.2 4.70408 15.145 3.38338 13.4999C4.70409 11.8549 6.73119 10.8 8.99989 10.8C11.2686 10.8 13.2958 11.855 14.6165 13.5001ZM9 9C10.4912 9 11.7 7.79116 11.7 6.3C11.7 4.80884 10.4912 3.6 9 3.6C7.50883 3.6 6.3 4.80884 6.3 6.3C6.3 7.79116 7.50883 9 9 9Z" fill="#00685E"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_4864_464">
                                    <rect width="18" height="18" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <p className="text-[#00685E] roboto text-[16px] font-[500]">{t('header.button2')}</p>
                        </div>
                    </a>
                </div>
               
            </div>
            <div className="xl:hidden flex mx-[20px] items-center justify-between h-[60px]">
                <Link to="/"><img src={logo_mob} alt="" className="w-[80px]"/></Link>
                <Dropdown
                    options={languages}
                    placeholder="EN"
                    onChange={handleChangeLang}
                    value={languages.find(lang => lang.value === currentLang)}
                    className="ml-auto mr-[20px]"
                />
                <MobileMenu/>
            </div>
        </div>
    );
}

export default Header;
